import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import 'bootstrap/dist/css/bootstrap.min.css';

import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  gql,
} from "@apollo/client";


const client = new ApolloClient({
  cache: new InMemoryCache(),
  uri: 'https://bhutanplus.getcto.asia/graphql',
  headers: {    authorization: `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOjE3MiwiYXV0aG9ySWQiOjUsImlhdCI6MTYxNDk1NTc2MSwiZXhwIjoxNjQ2NDkxNzYxfQ.DAlbRuOOu9XZXjXkWzNibqUYfU8iD9v8KNuuhLSv5wY`,  },
  typeDefs: gql`
    enum contentType {
      MARKDOWN
    }
  `
});


ReactDOM.render(
  <ApolloProvider client={client}>
    <App />
  </ApolloProvider>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
