import '../App.css'

import {
    Link
  } from "react-router-dom";

function Navbar() {
    return (
        <div className="navbar">
            <ul class="navList">
                <li>
                    <Link to="/">Story</Link>
                </li>
                <li>
                    <Link to="/cover">Cover</Link>
                </li>
            </ul>
        </div>
    )
}

export default Navbar;