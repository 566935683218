import React, { useState, useEffect } from 'react';
import "../App.css"
import { gql, useQuery, useMutation } from '@apollo/client';

import {
    Container, Row, Col,
    Card, CardImg, CardText, CardBody,
    CardTitle, CardSubtitle, Button,
    Modal, ModalHeader, ModalBody, ModalFooter,
    Form, FormGroup, Label, Input, Alert,
  } from 'reactstrap';

function Cover() {
    

    const { loading, error, data } = useQuery(GET_ACTIVITIES);
    
    const [dataFeed, setDataFeed] = useState(null);
    const [modal, setModal] = useState(false);
    const toggle = () => setModal(!modal);

    const [formVal, setFormVal] = useState({
        title: "",
        subtitle: "",
        descriptions: "",
        images: "",
    })

    const [addCover] = useMutation(ADD_COVER, {
        onCompleted: (d) => {
            alert("Cover story successful added.")
            setModal(false);
            setFormVal({
                title: "",
                subtitle: "",
                descriptions: "",
                images: "",
            });
        },
        onError: (err) => {
            console.log("add cover err.....", err.message);
            alert("Cover Stroy fetching error", err.message)
        }
    })

    if (loading) return 'Loading...';
    if (error) return `Error! ${error.message}`;
    
    

    const formSubmit = () => {
        console.log("data...", formVal);
        if(
            formVal.title.trim() !== "" && formVal.subtitle.trim() !== "" && formVal.descriptions.trim() !== "" && formVal.images.trim() !== ""
        ) {
            addCover({ variables: { title: formVal.title, subtitle: formVal.subtitle, descriptions: formVal.descriptions, images: formVal.images} })
        }else {
            console.log("missing data")
            alert("Please fill in all")
        }
    }

   



    return (
        <Container className="container">
            
            <Row className="content-holder" style={{justifyContent: 'center'}}>
                <h1>Cover Story</h1>

                
                    <Col sm="8" md="8" style={{textAlign: 'center', justifyContent: 'center'}}>
                        <Card>
                            <CardImg top width="100%" src={data.activities[data.activities.length - 1].images} alt="Card image cap"/>
                            <CardBody>
                                <CardTitle tag="h5">{data.activities[data.activities.length - 1].title}</CardTitle>
                                <CardSubtitle tag="h6" className="mb-2 text-muted">{data.activities[data.activities.length - 1].subtitle}</CardSubtitle>
                                <CardText>{data.activities[data.activities.length - 1].descriptions}</CardText>
                                <Button onClick={toggle}>Add New Cover Story</Button>
                            </CardBody>
                        </Card>
                    </Col>
                
                
                
            </Row>

            <Modal isOpen={modal} toggle={toggle} style={{maxWidth: 1000}}>
                <ModalHeader toggle={toggle}>Modal title</ModalHeader>
                <ModalBody>
                    <Form>
                        <FormGroup>
                            <Label for="bannerInput">Image URL</Label>
                            <Input type="text" name="banner" id="bannerInput" placeholder="Image URL" onChange={(e) => setFormVal({...formVal, images: e.target.value})} />
                        </FormGroup>

                        <FormGroup>
                            <Label for="titleInput">Title</Label>
                            <Input type="text" name="title" id="titleInput" placeholder="Title" onChange={(e) => setFormVal({...formVal, title: e.target.value})} />
                        </FormGroup>

                        <FormGroup>
                            <Label for="descInput">Subtitle</Label>
                            <Input type="text" name="description" id="descInput" placeholder="Subtitle" onChange={(e) => setFormVal({...formVal, subtitle: e.target.value})} />
                        </FormGroup>

                        <FormGroup>
                            <Label for="contentInput">Content</Label>
                            <Input type="textarea" name="content" id="contentInput" placeholder="Content HTML" onChange={(e) => setFormVal({...formVal, descriptions: e.target.value })} />
                        </FormGroup>
                    </Form>
                </ModalBody>
                <ModalFooter>
                <Button color="primary" onClick={() => formSubmit() }>Submit</Button>
                <Button color="secondary" onClick={toggle}>Cancel</Button>
                </ModalFooter>
            </Modal>
        </Container>
    )
}

const GET_ACTIVITIES = gql`
  query activities {
    activities(limit:100,offset:1,type: "ANNOUNCEMENT"){
        id
        title
        subtitle
        descriptions
        type
        images
    }
  }
`;

const ADD_COVER  = gql`
  mutation createActivity(
      $title: String!
      $subtitle: String!
      $descriptions: String!
      $images: String!
  ) {
    createActivity(createActivityInput: {
        title: $title,
        subtitle: $subtitle,
        descriptions: $descriptions,
        images: $images,
        type: ANNOUNCEMENT
      }) {
          id
          title
          subtitle
          descriptions
          images
          type
      }
  }
`;

export default Cover;