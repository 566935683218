import Story from './containers/Story';
import Cover from './containers/Cover';
import "./App.css";

import {
  BrowserRouter as Router,
  Switch,
  Route,
  // Link
} from "react-router-dom";

import Navbar from './components/Navbar'

function App() {
  return (
    <div className="App">
      
      <Router>
        <Navbar />
        <Switch>
            <Route exact path="/">
              <Story />
            </Route>
            <Route path="/cover">
              <Cover />
            </Route>
          </Switch>
      </Router>
    </div>
  );
}

export default App;
