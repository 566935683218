
import React, { useState } from 'react';
import "../App.css"
import { gql, useQuery, useMutation } from '@apollo/client';

import {
    Container, Row, Col,
    Card, CardImg, CardText, CardBody,
    CardTitle, CardSubtitle, Button,
    Modal, ModalHeader, ModalBody, ModalFooter,
    Form, FormGroup, Label, Input, Alert,
  } from 'reactstrap';

function Story() {
    const [modal, setModal] = useState(false);
    const toggle = () => setModal(!modal);

    const [formVal, setFormVal] = useState({
        title: "",
        description: "",
        image: "",
        content: "",
    })

    const { loading, error, data } = useQuery(GET_STORY);
    const [addStory] = useMutation(ADD_STORY, {
        onCompleted: (d) => {
            alert("Story successful added.")
            setModal(false);
            setFormVal({
                title: "",
                description: "",
                image: "",
                content: "",
            });
        },
        onError: (err) => {
            console.log("add stroy err.....", err.message);
            alert("Stroy fetching error", err.message)
        }
    })

    if (loading) return 'Loading...';
    if (error) return `Error! ${error.message}`;

    const formSubmit = () => {
        console.log("data...", formVal);
        if(
            formVal.title.trim() !== "" && formVal.description.trim() !== "" && formVal.content.trim() !== "" && formVal.image.trim() !== ""
        ) {
            addStory({ variables: { title: formVal.title, description: formVal.description, content: formVal.content, image: formVal.image, tags: ["featured"]} })
        }else {
            console.log("missing data")
            alert("Please fill in all")
        }
    }

    return (
        <Container className="container">
            
            <Row className="content-holder">
                <h1>Story</h1>
                <Button onClick={toggle}>Add New Story</Button>
                {
                    data.stories.map(d => <Col sm="6" md="6">
                        <Card key="d.id" style={{margin: 10}}>
                            <CardImg top width="100%" src={d.image} alt="Card image cap" />
                            <CardBody>
                                <CardTitle tag="h5">{d.title}</CardTitle>
                                <CardSubtitle tag="h6" className="mb-2 text-muted">{d.description}</CardSubtitle>
                                <CardText>{d.content}</CardText>
                                {/* <Button>Button</Button> */}
                            </CardBody>
                        </Card>
                    </Col>)
                }
            </Row>

            <Modal isOpen={modal} toggle={toggle} style={{maxWidth: 1000}}>
                <ModalHeader toggle={toggle}>Modal title</ModalHeader>
                <ModalBody>
                    <Form>
                        <FormGroup>
                            <Label for="bannerInput">Image URL</Label>
                            <Input type="text" name="banner" id="bannerInput" placeholder="Image URL" onChange={(e) => setFormVal({...formVal, image: e.target.value})} />
                        </FormGroup>

                        <FormGroup>
                            <Label for="titleInput">Title</Label>
                            <Input type="text" name="title" id="titleInput" placeholder="Title" onChange={(e) => setFormVal({...formVal, title: e.target.value})} />
                        </FormGroup>

                        <FormGroup>
                            <Label for="descInput">Description</Label>
                            <Input type="text" name="description" id="descInput" placeholder="Description" onChange={(e) => setFormVal({...formVal, description: e.target.value})} />
                        </FormGroup>

                        <FormGroup>
                            <Label for="contentInput">Content</Label>
                            <Input type="textarea" name="content" id="contentInput" placeholder="Content HTML" onChange={(e) => setFormVal({...formVal, content: e.target.value })} />
                        </FormGroup>
                    </Form>
                </ModalBody>
                <ModalFooter>
                <Button color="primary" onClick={() => formSubmit() }>Submit</Button>
                <Button color="secondary" onClick={toggle}>Cancel</Button>
                </ModalFooter>
            </Modal>
        </Container>
    )
}

const GET_STORY = gql`
  query GetStories {
    stories(limit:4,offset:0) {
        id,
        title,
        content,
        description,
        image,
        publishDate,
        author{id,bio}
      }
  }
`;

const ADD_STORY  = gql`
  mutation publishStory(
      $title: String!
      $description: String!
      $content: String!
      $image: String!
      $tags: [String!]!
  ) {
    publishStory(publishStoryInput:  {
            title: $title,
            description: $description,
            content: $content,
            image: $image,
            tags: $tags,
            contentType: MARKDOWN
        }
    ) {
        id
        title
        description
        content
    }
  }
`;

export default Story;